<template>
  <div>
    <div v-if="!loading">
      <div v-if="data.length > 0">
        <GChart :type="type" :data="data" :options="options" />
      </div>
    </div>
    <div v-else style="min-height: 100px;" class="d-flex justify-center align-center">
      <div class="text-center">
        <v-progress-circular size="50" indeterminate color="secondary"></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'LineChart'
    },
    data: {
      type: Array,
      default: function() {
        return [];
      }
    },
    options: {
      type: Object,
      default: function() {
        return {};
      }
    },
    loading: {
      type: Boolean
    }
  }
};
</script>