<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium third--text">
        {{ $t("dashboard.title") }}
      </div>
    </div>
    <v-card dark class="mb-7">
      <v-card-title>
        <div class="text-subtitle-1">{{ $t("commons.daterange.title") }}</div>
        <v-spacer></v-spacer>
        <v-btn outlined color="secondary" @click="changeDaterange()">{{
          dateRangeText
        }}</v-btn>
      </v-card-title>
    </v-card>
    <dashboard-summary
      class="mb-7"
      :summary="summary"
      :loading="loading.summary"
    ></dashboard-summary>
    <v-card dark class="mb-7">
      <v-card-title>
        <div class="text-subtitle-1">
          {{ $t("participations.statistics.filter.title") }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="secondary"
          @click="filterStatisticsParticipations()"
          ><v-icon left>mdi-filter-variant</v-icon>{{ $t("btn.filter") }}</v-btn
        >
      </v-card-title>
    </v-card>
    <v-row class="mb-7">
      <v-col cols="4" xs="12">
        <v-card dark>
          <v-card-title>
            <div class="text-subtitle-1">
              {{ $t("participations.statistics.title2") }}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <participation-table
            :participations="participationTable"
            v-if="!loading.participations"
          ></participation-table>
          <v-card-text class="text-center mt-5" v-else>
            <v-progress-circular
              indeterminate
              color="secondary"
              width="4"
              size="60"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8" xs="12">
        <v-card dark>
          <v-card-title>
            <div class="text-subtitle-1">
              {{ $t("participations.statistics.title") }}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="min-height: 120px" class="mx-auto mt-4">
            <div class="d-flex justify-end">
              <v-select
                :items="items.participations"
                v-model="groups.participation"
                :item-text="(item) => $t(item.text)"
                :label="$t('commons.groups.title')"
                dark
                :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 220px;' : ''"
                hide-details
                class="mt-0 pt-0 mb-2"
              >
              </v-select>
            </div>
            <chart
              v-if="$objectPath.has(participationChart.data, 1)"
              :loading="loading.participations"
              :data="participationChart.data"
              :options="participationChart.options"
            ></chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog.daterange.display"
      width="290px"
      persistent
      :overlay-opacity="'0.5'"
      :overlay-color="$vuetify.theme.themes.light.third"
    >
      <v-date-picker
        v-model="dialog.daterange.date"
        dark
        color="secondary"
        header-color="black"
        scrollable
        range
        event-color="third"
      >
        <v-spacer></v-spacer>
        <v-btn text color="third" @click="dialog.daterange.display = false">{{
          $t("btn.cancel")
        }}</v-btn>
        <v-btn text color="third" @click="saveDaterange()">
          <span class="font-weight-bold">{{ $t("btn.ok") }}</span>
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <participation-filter
      :dialog="dialog.filter"
      :hide-daterange="true"
      @applyFilter="applyParticipationsFilter"
      :hide-participant-identifier="true"
    ></participation-filter>
  </div>
</template>

<script>
import ParticipationTable from "./table";
import Chart from "./../commons/chart.vue";
import DashboardSummary from "./summary.vue";
import ParticipationFilter from "./../participations/filter";
import filterMixins from "./../../mixins/commons/filter";
import { mapActions } from "vuex";

export default {
  mixins: [filterMixins],
  created() {
    this.initDaterange();
  },
  data: () => ({
    daterange: [],
    filters: {},
    statistics: [],
    dialog: {
      daterange: {
        display: false,
        date: [],
      },
      filter: {
        display: false,
      },
    },
    summary: {
      promotion: 0,
      participant: 0,
      fraud: 0,
      reseller: 0,
    },
    loading: {
      summary: true,
      participations: true,
      exportParticipation: false,
    },
    items: {
      participations: [
        {
          text: "participations.statistics.group.promotion",
          value: "promotion",
        },
        {
          text: "participations.statistics.group.reseller",
          value: "reseller",
        },
        {
          text: "participations.statistics.group.usedCode",
          value: "usedCode",
        },
        {
          text: "participations.statistics.group.checkedCode",
          value: "checkedCode",
        },
      ],
    },
    groups: {
      participation: "promotion",
    },
    participationChart: {
      data: [],
      options: {},
    },
    participationTable: {},
    pivot: [],
  }),
  methods: {
    initDaterange() {
      const now = this.$moment();
      const prev = this.$moment().startOf("month").subtract(3, "days");
      this.daterange = [prev.format("YYYY-MM-DD"), now.format("YYYY-MM-DD")];
    },
    changeDaterange() {
      const daterange = this.daterange;
      this.dialog.daterange.date = daterange;
      this.dialog.daterange.display = true;
    },
    saveDaterange() {
      this.dialog.daterange.display = false;
      if (this.dialog.daterange.date.length !== 2) return;
      const daterange = this.dialog.daterange.date;
      this.daterange = this.$utils.daterangeAlign(daterange);
    },
    async getSummary() {
      this.loading.summary = true;
      try {
        const response = await this.request({
          url: "api/v1/summary",
          params: {
            start_date: this.daterange[0],
            end_date: this.daterange[1],
          },
          messages: {
            500: true,
          },
        });
        this.summary = response.data;
      } catch (error) {
        // empty
      }
      this.loading.summary = false;
    },
    async getStatisticsParticipations() {
      try {
        const self = this;
        const startdate = this.daterange[0];
        const enddate = this.daterange[1];

        this.loading.participations = true;

        const filterFormatted = this.filterApply(this.filters);

        const response = await this.request({
          url: "api/v1/participants/statistic",
          params: {
            created_gte: this.daterange[0],
            created_lte: this.daterange[1],
            groups: "reseller,promotion,usedCode,checkedCode,date",
            ...filterFormatted,
          },
        });
        this.statistics = response.data;
        const statistics = this.$utils.getParticipationsStatistics(
          this.statistics,
          this.groups.participation,
          startdate,
          enddate
        );
        this.participationTable = statistics.table;
        if (statistics.chart.data[0].length > 1) {
          this.participationChart = statistics.chart;
        } else {
          this.participationChart = {
            data: [],
            options: {},
          };
        }
        this.$nextTick(function () {
          self.loading.participations = false;
        });
      } catch (error) {
        // emtpy
        this.loading.participations = false;
      }
    },
    filterStatisticsParticipations() {
      this.dialog.filter.display = true;
    },
    applyParticipationsFilter(filters) {
      this.filters = filters;
      this.getStatisticsParticipations();
    },
    ...mapActions({ request: "request" }),
  },
  watch: {
    daterange: {
      handler() {
        this.getSummary();
        this.getStatisticsParticipations();
      },
      deep: true,
    },
    "groups.participation"() {
      const startdate = this.daterange[0];
      const enddate = this.daterange[1];
      const statistics = this.$utils.getParticipationsStatistics(
        this.statistics,
        this.groups.participation,
        startdate,
        enddate
      );
      if (statistics.chart.data[0].length > 1) {
        this.participationChart = statistics.chart;
      } else {
        this.participationChart = {
          data: [],
          options: {},
        };
      }
    },
  },
  computed: {
    dateRangeText() {
      return this.daterange[0] + " - " + this.daterange[1];
    },
    hasChartData() {
      return this.participationChart.data.length > 1;
    },
  },
  components: {
    DashboardSummary,
    ParticipationFilter,
    Chart,
    ParticipationTable,
  },
};
</script>