<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="blue-grey darken-4" class="white--text">
            <v-card-title primary-title style="height: 110px;">
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 60 : 50">mdi-sale</v-icon>
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ $utils.pad(summary.promotion, 2) }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-2">
                    {{ $t("statistics.fields.promotion.title") }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider light></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("statistics.fields.promotion.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="indigo darken-4" class="white--text">
            <v-card-title primary-title style="height: 110px;">
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 60 : 50">mdi-hand</v-icon>
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ $utils.pad(summary.participant, 2) }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-2">
                    {{ $t("statistics.fields.participation.title") }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider light></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("statistics.fields.participation.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card color="red darken-4" class="white--text">
            <v-card-title primary-title style="height: 110px;">
              <v-row align="center">
                <v-col cols="2" align-self-center>
                  <div class="my-auto">
                    <v-icon left dark :size="hover ? 60 : 50">mdi-cancel</v-icon>
                  </div>
                </v-col>
                <v-col cols="10">
                  <template v-if="!loading">
                    <div class="text-right">
                      <span class="text-h3">{{ $utils.pad(summary.fraud, 2)}}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-xs-right">
                      <span class="text-h3">...</span>
                    </div>
                  </template>
                  <div class="text-right text-body-2">
                    {{ $t("statistics.fields.fraud.title") }}
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              class="my-0"
              color="white"
              height="1"
              :indeterminate="loading"
            ></v-progress-linear>
            <v-divider light></v-divider>
            <v-card-actions>
              <span class="text-body-2">{{
                $t("statistics.fields.fraud.description")
              }}</span>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    summary: {
      type: Object,
      default: function () {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
};
</script>