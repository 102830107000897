<template>
  <div>
    <dashboard></dashboard>
  </div>
</template>

<script>
import Dashboard from "./../components/dashboard/index";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("dashboard.title")
    };
  },
  components: {
    Dashboard
  }
};
</script>