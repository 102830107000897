<template>
  <v-simple-table dark>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t("commons.created.title2") }}
          </th>
          <th class="text-right">
            {{ $t("participations.statistics.subtitle2") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, k) in participations" :key="k">
          <td>{{ k }}</td>
          <td class="text-right">{{ value }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td class="text-left">{{ $t("commons.total") }}</td>
          <td class="text-right">{{ participationTotal }}</td>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    participations: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data: () => ({}),
  computed: {
    participationTotal() {
      let total = 0;
      for (const prop in this.participations) {
        total += this.participations[prop];
      }
      return total;
    }
  }
}
</script>